import { SvgTwitter } from "melodies-source/Svgs/Twitter";
import { SvgFacebook } from "melodies-source/Svgs/Facebook";
import { SvgInstagram } from "melodies-source/Svgs/Instagram";
import { SvgSnapchat } from "melodies-source/Svgs/Snapchat";
import styled from "styled-components";
import { SurveyConfigModule } from "@max/common/setfan";

export const SocialMedia = ({
  header,
  twitter,
  facebook,
  instagram,
  snapchat,
  color,
}: SurveyConfigModule) => {
  return (
    <Container>
      <Header {...header} />
      <Socials color={color}>
        {twitter && (
          <SocialLink href={twitter}>
            <SvgTwitter />
          </SocialLink>
        )}
        {facebook && (
          <SocialLink href={facebook}>
            <SvgFacebook />
          </SocialLink>
        )}
        {instagram && (
          <SocialLink href={instagram}>
            <SvgInstagram />
          </SocialLink>
        )}
        {snapchat && (
          <SocialLink href={snapchat}>
            <SvgSnapchat />
          </SocialLink>
        )}
      </Socials>
    </Container>
  );
};

const SocialLink = styled.a.attrs(() => ({ target: "__blank" }))``;

const Header = styled.div`
  text-align: center;
  font-size: 14px;
  padding: 10px;
`;
const Container = styled.div`
  padding-bottom: 12px;
`;
const Socials = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  a {
    color: ${(props) => props.color || "#000"};
  }
  svg {
    width: 30px;
    height: 30px;
    margin: 5px;
  }
`;
