import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
} from "Components";
import { TextInput } from "melodies-source/TextInput";

export const YearBorn = ({
  id,
  style,
  header,
  visibility,
  validation,
  ...props
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.[id] as SurveyResponse<string>;
  const handleChange = (text) => {
    const year = parseInt(text);
    let ndata = { ...data };
    if (year < new Date().getFullYear() - 21) {
      ndata["profiling.ofAge"] = {
        value: year < new Date().getFullYear() - 21,
      };
    } else {
      delete ndata["profiling.ofAge"];
    }
    let update = {
      ...ndata,
      [id]: {
        ...d,
        value: text,
      },
    };

    setData(update);
  };

  const valid = useValidation(id, validation);

  return (
    <QuestionContainer noBorder={!!props.noBorder}>
      <HeadlineText {...header} />
      <TextInput
        type="text"
        value={d?.value || ""}
        onChange={handleChange}
        style={{ ...style, marginTop: 10 }}
      />
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};
