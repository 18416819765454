import { SurveyConfigModule } from "@max/common/setfan";
import { Module } from "Components";
import styled from "styled-components";

export const Page = (parent: SurveyConfigModule) => {
  const { modules, id, ...props } = parent;

  return (
    <PageContainer {...props}>
      {modules?.map((m) => (
        <Module {...m} parent={parent} key={m.id} />
      ))}
    </PageContainer>
  );
};

const PageContainer = styled.div<{ compact?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ compact }) => (compact ? "48px" : "78px")} 20px 44px 20px;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: ${({ compact }) => (compact ? "28px" : "58px")} 0;
  }
`;
