import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
} from "Components";
import { CountrySelect } from "melodies-source/CountryPhone/CountrySelect";

export const CountryQuestion = ({
  id,
  style,
  header,
  validation,
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.[id] as SurveyResponse<any>;
  const valid = useValidation(id, validation);

  const handleChange = (text) => {
    setData({
      ...data,
      [id]: {
        ...d,
        value: text,
      },
    });
  };

  return (
    <QuestionContainer noBorder>
      <HeadlineText {...header} style={{ marginBottom: 10 }} />
      <CountrySelect
        standalone={true}
        value={d?.value || ""}
        setValue={handleChange}
      />
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};
