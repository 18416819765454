import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Caret from "Components/SVGs/Caret";
import { SurveyConfigOption } from "@max/common/setfan";

type Props = {
  badge?: JSX.Element;
  header: JSX.Element;
  items: SurveyConfigOption[];
  renderFunc: (item: SurveyConfigOption) => JSX.Element;
};
export const Accordion = ({ badge, header, items, renderFunc }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [bodyHeight, setBodyHeight] = useState(0);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth });
  const handleResize = () => {
    setDimensions({ width: window.innerWidth });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const bodyRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (bodyRef?.current) {
      setBodyHeight(bodyRef.current.scrollHeight);
    }
  }, [bodyRef, dimensions.width]);

  return (
    <CollapseContainer>
      <CollapseHeader onClick={() => setIsOpen(!isOpen)}>
        {header}
        <CaretContainer>
          {badge}
          <StyledCaret direction={isOpen ? "up" : "down"} />
        </CaretContainer>
      </CollapseHeader>
      <CollapseBody height={isOpen ? bodyHeight : 0} ref={bodyRef}>
        {items.map((item) => renderFunc(item))}
      </CollapseBody>
    </CollapseContainer>
  );
};

const CaretContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 3px;
`;

const CollapseBody = styled.div<{ height: number }>`
  overflow: hidden;
  height: ${({ height }) => height}px;
  opacity: ${({ height }) => (height ? 1 : 0)};
  margin-top: 10px;

  & > div {
    margin-bottom: 15px;

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-bottom: 20px;
    }
  }

  transition: all 0.3s;
`;

const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CollapseHeader = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
  margin: 0 0 10px 0;
  cursor: pointer;
`;

const StyledCaret = styled(Caret)<{ direction: "up" | "down" }>`
  transform: scaleY(${({ direction }) => (direction === "up" ? 1 : -1)});

  transition: all 0.4s;
`;
