import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import { HeadlineText, QuestionContainer, useDataContext } from "Components";
import { Checkbox } from "melodies-source/Selectable";
import styled from "styled-components";

export const OfAge = ({
  id,
  style,
  header,
  options,
  visibility,
  label,
  ...props
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.["profiling.ofAge"] as SurveyResponse<boolean>;
  const handleClick = () => {
    let ndata = { ...data };
    if (ndata?.["profiling.ofAge"]) {
      delete ndata["profiling.ofAge"];
    } else {
      ndata = {
        ...data,
        "profiling.ofAge": {
          value: true,
        },
      };
    }

    setData(ndata);
  };

  return (
    <QuestionContainer {...props}>
      <HeadlineText {...header} />
      <MultipleSelectContainer>
        <Checkbox
          value={d?.value}
          onChange={() => handleClick()}
          label={label}
        />
      </MultipleSelectContainer>
    </QuestionContainer>
  );
};

const MultipleSelectContainer = styled.div`
  padding: 20px;
`;
