import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useSurveyContext,
  useValidation,
  Validation,
} from "Components";
import { Checkbox } from "melodies-source/Selectable";
import styled from "styled-components";

export const Confirm = ({
  id,
  style,
  header,
  options,
  visibility,
  validation,
  ...props
}: SurveyConfigModule) => {
  const { progressiveProfiling } = useSurveyContext();
  const { data, setData } = useDataContext();

  const d = data?.[id] as SurveyResponse<(string | number)[]>;
  const handleClick = (clickedId: string) => {
    const old = d?.value ?? [];
    const newVal = old.includes(clickedId)
      ? [...old.filter((i) => i !== clickedId)]
      : [...old, clickedId];
    setData({
      ...data,
      [id]: {
        ...d,
        value: newVal,
      },
    });
  };
  const valid = useValidation(id, validation);

  if (progressiveProfiling?.optInText) {
    options = options.filter((opt) => opt.id !== "optInText");
  }

  return (
    <QuestionContainer {...props}>
      {header && <HeadlineText {...header} />}
      <MultipleSelectContainer>
        {options.map(({ id, label, htmlLabel, ...o }) => (
          <Checkbox
            value={d?.value?.includes(id)}
            onChange={() => handleClick(id)}
            label={
              htmlLabel ? (
                <div dangerouslySetInnerHTML={{ __html: htmlLabel }} />
              ) : (
                label
              )
            }
            key={`confirm-select-${id}`}
            style={{ marginBottom: 8 }}
            {...o}
          />
        ))}
      </MultipleSelectContainer>
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};

const MultipleSelectContainer = styled.div``;
