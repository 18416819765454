import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
} from "Components";
import { TextInput } from "melodies-source/TextInput";
import styled from "styled-components";

export const NameQuestion = ({
  id,
  style,
  header,
  visibility,
  validation,
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.[id] as SurveyResponse<string[]>;
  const handleChange = (first, last) => {
    setData({
      ...data,
      [id]: {
        ...d,
        value: [first, last],
      },
    });
  };
  const valid = useValidation(id, validation);

  return (
    <QuestionContainer style={style} noBorder>
      <HeadlineText {...header} />
      <Container>
        <TextInput
          type="text"
          value={d?.value?.[0] || ""}
          onChange={(e) => handleChange(e, d?.value?.[1])}
          placeholder="First"
        />
        <TextInput
          type="text"
          value={d?.value?.[1] || ""}
          className={"lastName"}
          onChange={(e) => handleChange(d?.value?.[0], e)}
          placeholder="Last"
        />
      </Container>
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};

const Container = styled.div`
  display: flex;
  margin-top: 10px;
  input {
    border-radius: 6px 0 0 6px;
  }
  .lastName input {
    border-radius: 0 6px 6px 0;
  }
`;
