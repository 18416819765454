import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
} from "Components";
import { TextInput } from "melodies-source/TextInput";
import { Textarea } from "melodies-source/Textarea";

export const TextQuestion = ({
  id,
  style,
  header,
  validation,
  noBorder,
  multiline,
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.[id] as SurveyResponse<string>;
  const handleChange = (text: string) => {
    setData({
      ...data,
      [id]: {
        ...d,
        value: text,
      },
    });
  };
  const valid = useValidation(id, validation);

  return (
    <QuestionContainer noBorder={!!noBorder}>
      <HeadlineText {...header} />
      {multiline ? (
        <Textarea
          value={d?.value || ""}
          placeholder="Please enter a response"
          onChange={handleChange}
          style={{ ...style, marginTop: 10 }}
        />
      ) : (
        <TextInput
          type="text"
          value={d?.value || ""}
          placeholder="Please enter a response"
          onChange={handleChange}
          style={{ ...style, marginTop: 10 }}
        />
      )}
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};
