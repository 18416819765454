import { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { useSurveyContext } from "./SurveyContext";

export const ApplyGlobalStyles = () => {
  const { survey } = useSurveyContext();

  const [Global, setGlobal] = useState(null);

  useEffect(() => {
    if (survey.config.globalStyles) {
      setGlobal(createGlobalStyle`${survey.config.globalStyles}`);
    }
  }, [survey?.config?.globalStyles]);

  if (Global) {
    return <Global />;
  } else {
    return null;
  }
};
