import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
  shuffleArray,
  HeadlineText,
} from "Components";
import { Checkbox } from "melodies-source/Selectable";
import { TextInput } from "melodies-source/TextInput";
import { useMemo } from "react";
import styled from "styled-components";

export const MultipleSelectQuestion = ({
  id,
  header,
  options,
  validation,
  randomizeOptions,
  noBorder,
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.[id] as SurveyResponse<number[]>;

  const handleClick = (clickedId: number) => {
    const old = d?.value ?? [];
    const newVal = old.includes(clickedId)
      ? [...old.filter((i) => i !== clickedId)]
      : [...old, clickedId];
    setData({
      ...data,
      [id]: {
        ...d,
        value: newVal,
      },
    });
  };

  const handleUserDefined = (clickedId: number, text: string) => {
    const old = d?.userValue ?? [];
    const newUserVal = { ...old, [clickedId]: text };
    setData({
      ...data,
      [id]: {
        ...d,
        userValue: newUserVal,
      },
    });
  };

  const valid = useValidation(id, validation);

  const optionsArray = useMemo(
    () => (randomizeOptions ? shuffleArray([...options]) : options),
    [options, randomizeOptions],
  );

  const noNones = optionsArray
    .filter((o) => !(o.id === "none"))
    .filter((o) => !o.userDefined);

  const nones = [...optionsArray].filter((o) => o.id === "none");

  const userDefinedOptions = optionsArray
    .filter((o) => o.id !== "none")
    .filter((o) => o.userDefined);

  const opts = [...noNones, ...nones, ...userDefinedOptions];

  return (
    <QuestionContainer noBorder={!!noBorder}>
      <HeadlineText {...header} />
      <MultipleSelectContainer>
        {opts.map(({ id, ...o }) =>
          o.userDefined ? (
            <SelectableContainer key={`user-defined-select-${id}`}>
              <Checkbox
                value={d?.value?.includes(id)}
                onChange={() => handleClick(id)}
                label={o.label || "other"}
                style={{ width: "auto", marginRight: 8 }}
              />
              {d?.value?.includes(id) && (
                <SmallTextInput
                  value={d?.userValue?.[id]}
                  onChange={(text) => handleUserDefined(id, text)}
                  style={{
                    marginRight: 30,
                    maxWidth: 225,
                    flex: 1,
                  }}
                />
              )}
            </SelectableContainer>
          ) : (
            <Checkbox
              {...o}
              value={d?.value?.includes(id)}
              onChange={() => handleClick(id)}
              key={`select-${id}`}
            />
          ),
        )}
      </MultipleSelectContainer>
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};

const SmallTextInput = styled(TextInput)`
  & input {
    height: 30px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-left: 30px;
    margin-top: 4px;
  }
`;

const MultipleSelectContainer = styled.div`
  margin-left: 1px;
  margin-top: 10px;

  & > :not(:last-child) {
    margin-bottom: 15px;

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-bottom: 20px;
    }
  }
`;

const SelectableContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: normal;
    flex-direction: column;
  }
`;
