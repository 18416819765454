import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
} from "Components";
import { Radio } from "melodies-source/Selectable";
import { TextInput } from "melodies-source/TextInput";
import styled from "styled-components";

export const SingleSelectQuestion = ({
  id,
  header,
  options,
  validation,
  hidden,
  noBorder,
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.[id] as SurveyResponse<string>;

  const sortedOptions = (() => {
    const surveyOptions = options.filter((opt) => !opt.userDefined);
    const userOptions = options.filter((opt) => opt.userDefined);
    return [...surveyOptions, ...userOptions];
  })();

  const handleClick = (clickedId: number | string) => {
    setData({
      ...data,
      [id]: {
        ...d,
        value: clickedId,
      },
    });
  };

  const handleUserDefined = (clickedId: number | string, text: string) => {
    const old = d?.userValue ?? [];
    const newUserVal = { ...old, [clickedId]: text };
    setData({
      ...data,
      [id]: {
        ...d,
        userValue: newUserVal,
      },
    });
  };

  const valid = useValidation(id, validation);

  return hidden ? null : (
    <QuestionContainer noBorder={!!noBorder}>
      <HeadlineText {...header} />
      <SingleSelectContainer>
        {sortedOptions.map(({ id, ...o }) =>
          o.userDefined ? (
            <SelectableContainer key={`user-defined-select-${id}`}>
              <Radio
                value={d?.value === id}
                onChange={() => handleClick(id)}
                label={o.label || "other"}
                style={{ width: "auto", marginRight: 8 }}
              />
              {d?.value === id && (
                <OtherTextInput
                  value={d?.userValue?.[id]}
                  onChange={(text) => handleUserDefined(id, text)}
                  style={{
                    marginRight: 30,
                    maxWidth: 225,
                    flex: 1,
                  }}
                />
              )}
            </SelectableContainer>
          ) : (
            <Radio
              value={d?.value === id}
              onChange={() => handleClick(id)}
              key={`select-${id}`}
              {...o}
            />
          ),
        )}
      </SingleSelectContainer>
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};

const OtherTextInput = styled(TextInput)`
  & input {
    height: 30px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-left: 30px;
    margin-top: 4px;
  }
`;

const SingleSelectContainer = styled.div`
  margin-left: 1px;
  margin-top: 10px;

  & > :not(:last-child) {
    margin-bottom: 15px;

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-bottom: 20px;
    }
  }
`;

const SelectableContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: normal;
    flex-direction: column;
  }
`;
