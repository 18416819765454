import * as React from "react";
import { SVGProps } from "react";
export const SvgTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.03168,16 C13.06944,16 16.37184,10.9981217 16.37184,6.66051545 C16.37184,6.51844572 16.37184,6.37701595 16.36224,6.23622613 C17.0046921,5.77156268 17.5592675,5.19622416 18,4.53714901 C17.4008826,4.80260295 16.7653307,4.97668756 16.11456,5.05359166 C16.7998362,4.64336985 17.312719,3.99816206 17.55776,3.23804296 C16.9133808,3.62038671 16.2084135,3.88984352 15.47328,4.03478534 C14.455733,2.95287593 12.8388604,2.68807479 11.5293115,3.38886721 C10.2197627,4.08965963 9.54321599,5.58176193 9.87904,7.02848883 C7.23961327,6.89617811 4.7804645,5.64959545 3.1136,3.59897686 C2.24231856,5.09880102 2.68735225,7.01751969 4.12992,7.98073997 C3.60751437,7.96525792 3.0964988,7.82434371 2.64,7.56988968 C2.64,7.58332871 2.64,7.59740769 2.64,7.61148667 C2.64042761,9.17399317 3.74192095,10.5197788 5.2736,10.829174 C4.79031654,10.9609661 4.28325158,10.9802314 3.79136,10.8854899 C4.22140667,12.2226238 5.45380772,13.1386286 6.85824,13.165005 C5.69583042,14.0784954 4.25987809,14.5743928 2.78144,14.5729032 C2.52025828,14.5724018 2.2593306,14.5565892 2,14.5255466 C3.50120701,15.488854 5.24794458,15.9998188 7.03168,15.9974402"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
