import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
} from "Components";
import { CountryPhone } from "melodies-source/CountryPhone";

export const PhoneQuestion = ({
  id,
  style,
  header,
  validation,
  variant,
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.[id] as SurveyResponse<string>;
  const noCountryVariant = variant === "no-country";

  const handleChange = (text) => {
    if (noCountryVariant) {
      setData({
        ...data,
        [id]: {
          ...d,
          value: text.value,
        },
      });
    } else {
      setData({
        ...data,
        [id]: {
          ...d,
          value: text.value,
        },
        countryCode: {
          ...(data?.countryCode || {}),
          value: text.countryCode,
        },
      });
    }
  };
  const valid = useValidation(id, validation);

  return (
    <QuestionContainer noBorder>
      {/* margin bottom here because country phone overrides any style props passed */}
      <HeadlineText {...header} style={{ marginBottom: 10 }} />
      <CountryPhone
        value={d?.value || ""}
        onChange={handleChange}
        style={style}
        withCountrySelect={!noCountryVariant}
      />
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};
