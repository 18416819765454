import { SurveyConfigModule } from "@max/common/setfan";
import { useDataContext, useSurveyContext } from "Components";
import styled from "styled-components";

export const Progress = ({ color, ...props }: SurveyConfigModule) => {
  const { survey } = useSurveyContext();
  const { view } = useDataContext();
  return (
    <Container {...props}>
      {survey.pages.map((_, i) => (
        <Bubble
          style={{
            background: i <= view ? color || "var(--max-action)" : "#D9D9D9",
          }}
          key={`progress-bubble-${i}`}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0 10px 0;
`;

const Bubble = styled.div`
  height: 6px;
  border-radius: 4px;
  margin: 14px;
  flex-grow: 1;
`;
