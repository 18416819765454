import { SurveyConfigModule } from "@max/common/setfan";
import { Module } from "Components";
import styled from "styled-components";

export const Container = (parent: SurveyConfigModule) => {
  const { modules, id, ...props } = parent;
  return (
    <Contain {...props}>
      {modules?.map((m) => (
        <Module {...m} parent={parent} key={m.id} />
      ))}
    </Contain>
  );
};

const Contain = styled.div`
  display: flex;
  flex-direction: column;
`;
