import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
  Text,
} from "Components";
import styled from "styled-components";
import Check from "Components/SVGs/Check";
import { hueFromHex, useActionColor } from "../../color";
import { SurveyConfigModule } from "@max/common/setfan";

export const ImageQuestion = ({
  id,
  style,
  header,
  options,
  visibility,
  validation,
  ...props
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.[id];
  const handleClick = (image) => {
    setData({
      ...data,
      [id]: {
        ...d,
        value: image,
      },
    });
  };

  const valid = useValidation(id, validation);

  const { color: actionColor } = useActionColor();

  const selectedColor = (() => {
    const actionHue = hueFromHex(actionColor);
    if (actionHue <= 25 || actionHue >= 335) {
      return "#333";
    }
    return actionColor;
  })();

  return (
    <QuestionContainer {...props}>
      <HeadlineText {...header} />
      <ImagesContainer>
        {options.map(({ id, src, label, ...o }) => (
          <ImageOptionContainer
            onClick={() => handleClick(id)}
            selected={d?.value === id}
            fillColor={selectedColor}
            key={`image-option-${id}`}
            {...o}
          >
            <StyledImageOption src={src} alt={header.children} />
            {d?.value === id && (
              <CheckWrapper>
                <div />
                <PositionedCheck fillColor={selectedColor} />
              </CheckWrapper>
            )}
            {props.showLabels && !!label && (
              <Text
                type="text"
                style={{ marginTop: -5, paddingBottom: 5, textAlign: "center" }}
                variants={{ mobile: "Caption", desktop: "Caption" }}
                content={label}
              />
            )}
          </ImageOptionContainer>
        ))}
      </ImagesContainer>
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};

const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: auto;
  gap: 10px;
  margin: 20px 0;
`;

const StyledImageOption = styled.img`
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  aspect-ratio: 1;
  object-fit: contain;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 13px;
  }
`;

const ImageOptionContainer = styled.div<{
  selected: boolean;
  fillColor: string;
}>`
  transition: transform ease 100ms;
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
  ${(props) =>
    props.selected &&
    `
    border: 3px solid ${props.fillColor};
    z-index: 1;
  `}
`;

const PositionedCheck = styled(Check)`
  display: block;
  position: relative;
`;

const CheckWrapper = styled.div`
  position: absolute;
  top: -14px;
  right: -14px;
  div {
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    background: #fff;
    border-radius: 50%;
  }
`;
