// default up
const Caret = ({ className }: { className?: string }) => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.5429 7.45711C0.369333 7.28354 0.350049 7.01411 0.485046 6.81925L0.5429 6.75L6.89645 0.396446L13.25 6.75C13.4453 6.94526 13.4453 7.26184 13.25 7.45711C13.0764 7.63067 12.807 7.64996 12.6121 7.51496L12.5429 7.45711L6.89645 1.81055L1.25001 7.45711C1.07644 7.63067 0.807016 7.64996 0.612148 7.51496L0.5429 7.45711Z"
        fill="black"
      />
    </svg>
  );
};

export default Caret;
