import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
} from "Components";
import { Accordion } from "Components/Accordion";
import Close from "Components/SVGs/Close";
import { Checkbox } from "melodies-source/Selectable";
import { TextInput } from "melodies-source/TextInput";
import { useIsDesktop } from "melodies-source/utils/hooks";
import { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import albumBg from "images/default-spotify-album-bg.png";
import { MusicNote } from "Components/SVGs/MusicNote";

export const SongsQuestion = ({
  id,
  header,
  options,
  validation,
}: SurveyConfigModule) => {
  const isDesktop = useIsDesktop();

  const [songSearch, setSongSearch] = useState("");
  const { data, setData } = useDataContext();
  const d = data?.[id] as SurveyResponse<string[]>;

  const handleClick = (songId: string, checked: boolean) => {
    const currentSelections = d?.value ?? [];
    let newSelections: string[];
    if (checked) {
      newSelections = [...currentSelections, songId];
    } else {
      currentSelections.splice(currentSelections.indexOf(songId), 1);
      newSelections = [...currentSelections];
    }
    setData({
      ...data,
      [id]: {
        ...d,
        value: newSelections,
      },
    });
  };

  const valid = useValidation(id, validation);

  const selectedAlbums = useMemo(
    () =>
      d?.value?.reduce((acc, songId) => {
        const album = options.find((albumOpt) =>
          albumOpt.options?.find((songOpt) => songOpt.id === songId),
        );
        if (album && !acc[album.id]) {
          acc[album.id] = { src: album.src };
        }
        return acc;
      }, {} as Record<string, { src: string }>),
    [d?.value, options],
  );

  const albumSongsSelected = useMemo(
    () =>
      options.reduce((albumSongs, albumOpt) => {
        const songsCount = albumOpt.options.reduce((acc, song) => {
          if (d?.value?.includes(song.id)) {
            acc += 1;
          }
          return acc;
        }, 0);
        albumSongs[albumOpt.id] = songsCount;
        return albumSongs;
      }, {} as Record<string, number>),
    [d?.value, options],
  );

  return (
    <>
      <QuestionContainer>
        <HeadlineText {...header} />
        <TextInput
          value={songSearch}
          rightIcon={
            songSearch ? (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setSongSearch("")}
              >
                <Close />
              </div>
            ) : undefined
          }
          onChange={(text) => setSongSearch(text)}
          style={{ marginTop: 10, marginBottom: 10 }}
          placeholder="Search for a song..."
        />
        <div style={{ marginLeft: 5 }}>
          {!songSearch ? (
            options.map((opt) => {
              const songCount = albumSongsSelected[opt.id];
              const badgeText =
                songCount > 0 ? songCount.toString() : undefined;

              const album = opt.src ? (
                <StyledAlbum src={opt.src} alt="album" />
              ) : (
                <DefaultAlbum />
              );

              const headerText = <StyledHeader>{opt.label}</StyledHeader>;
              const badge =
                !!badgeText && isDesktop ? (
                  <Badge>
                    <BadgeText>{badgeText}</BadgeText>
                  </Badge>
                ) : undefined;

              return (
                <AccordionContainer key={`album-${opt.id}`}>
                  <Accordion
                    badge={badge}
                    header={
                      <>
                        {album}
                        {headerText}
                      </>
                    }
                    items={opt.options}
                    renderFunc={(item) => (
                      <Checkbox
                        label={item.label}
                        value={d?.value?.includes(item.id)}
                        onChange={(checked) => handleClick(item.id, checked)}
                        key={`select-${item.id}`}
                      />
                    )}
                  />
                </AccordionContainer>
              );
            })
          ) : (
            <SongListContainer>
              {options
                .reduce((acc, curr) => {
                  acc.push(...curr.options);
                  return acc;
                }, [])
                .filter((item) =>
                  item.label.toLowerCase().includes(songSearch.toLowerCase()),
                )
                .map((song) => (
                  <Checkbox
                    label={song.label}
                    value={d?.value?.includes(song.id)}
                    onChange={(checked) => handleClick(song.id, checked)}
                    key={`select-${song.id}`}
                  />
                ))}
            </SongListContainer>
          )}
        </div>
        {valid && <Validation>{valid}</Validation>}
      </QuestionContainer>
      {!isDesktop &&
        createPortal(
          <MobileAlbumFooter
            selectedAlbums={selectedAlbums}
            selectedSongs={
              albumSongsSelected &&
              Object.values(albumSongsSelected).reduce((acc, curr) => {
                acc += curr;
                return acc;
              }, 0)
            }
          />,
          document.getElementById("root"),
        )}
    </>
  );
};

const MobileAlbumFooter = ({
  selectedAlbums,
  selectedSongs,
}: {
  selectedAlbums: Record<string, { src: string }>;
  selectedSongs: number;
}) => {
  const overflow = selectedAlbums && Object.keys(selectedAlbums).length > 3;

  if (selectedSongs < 1) {
    return null;
  }

  return (
    <MobileStickyContainer>
      {selectedAlbums &&
        Object.values(selectedAlbums)
          .slice(0, 3)
          .map((alb) =>
            alb.src ? (
              <div>
                <FooterAlbum
                  src={alb.src}
                  alt="album"
                  key={`${alb.src}-footer`}
                />
              </div>
            ) : (
              <DefaultAlbum small key="default-album-footer" />
            ),
          )}
      {overflow && (
        <FooterOverflowAlbum>{`+${
          Object.keys(selectedAlbums).length - 3
        }`}</FooterOverflowAlbum>
      )}
      <FooterSongCount>{`${selectedSongs} Song${
        selectedSongs === 1 ? "" : "s"
      } Selected`}</FooterSongCount>
    </MobileStickyContainer>
  );
};

export const DefaultAlbum = ({ small }: { small?: boolean }) => {
  if (small) {
    return (
      <div style={{ position: "relative", marginRight: 5 }}>
        <img
          src={albumBg}
          alt="default album cover"
          style={{ width: 32, height: 32 }}
        />
        <StyledSmallMusicNote />
      </div>
    );
  }

  return (
    <div style={{ position: "relative", marginRight: 10 }}>
      <img
        src={albumBg}
        alt="default album cover"
        style={{ width: 60, height: 60 }}
      />
      <StyledMusicNote />
    </div>
  );
};

const AccordionContainer = styled.div`
  display: flex;
`;

const Badge = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #999999;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-right: 10px;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    margin-left: 10px;
  }
`;

const BadgeText = styled.span`
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;
const FooterAlbum = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 5px;
`;

const FooterOverflowAlbum = styled.div`
  width: 32px;
  height: 32px;
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #666;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  line-height: 22px;
`;

const FooterSongCount = styled.div`
  margin-left: auto;
  color: #333;
  font-family: Poppins;
  font-size: 15px;
  line-height: 22px;
`;

const MobileStickyContainer = styled.div`
  height: 58px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 14px 21px;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
`;

const SongListContainer = styled.div`
  margin-top: 15px;
  margin-left: 5px;

  & > div {
    margin-bottom: 15px;

    ${({ theme }) => theme.mediaQueries.mobile} {
      margin-bottom: 20px;
    }
  }

  transition: all 0.3s;
`;

const StyledAlbum = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
`;

const StyledHeader = styled.div`
  color: #333;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledMusicNote = styled(MusicNote)`
  position: absolute;
  top: 16px;
  left: 15px;
  & > path {
    fill: white;
  }
`;

const StyledSmallMusicNote = styled(MusicNote)`
  position: absolute;
  top: 9px;
  left: 8px;
  width: 15px;
  height: 15px;
  & > path {
    fill: white;
  }
`;
