import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
} from "Components";
import Dropdown from "Components/Dropdown";

export const RankedQuestion = ({
  id,
  style,
  header,
  visibility,
  validation,
  ...props
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.[id] as SurveyResponse<string>;

  const handleChange = (selection: string | number) => {
    setData({
      ...data,
      [id]: {
        ...d,
        value: selection,
      },
    });
  };

  const valid = useValidation(id, validation);

  const options = Array.from({ length: Number(props.rankRange) }).map(
    (_, i) => {
      const stringValue = `${i + 1}`;
      return {
        id: stringValue,
        label: `${stringValue} ${
          !i
            ? `(${props.rankLow})`
            : i === props.rankRange - 1
            ? `(${props.rankHigh})`
            : ""
        }`,
      };
    },
  );

  return (
    <QuestionContainer noBorder={!!props.noBorder}>
      <HeadlineText {...header} />
      <Dropdown
        {...props}
        placeholder="Please choose a rating"
        options={props.displayOrder === "asc" ? options : options.reverse()}
        handleChange={handleChange}
        value={d?.value}
      />
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};
