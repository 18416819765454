const Close = ({ className }: { className?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2ZM7.6129 6.2097C7.22061 5.90468 6.65338 5.93241 6.29289 6.29289C5.93241 6.65338 5.90468 7.22061 6.2097 7.6129L6.29289 7.70711L8.585 10L6.29289 12.2929L6.2097 12.3871C5.90468 12.7794 5.93241 13.3466 6.29289 13.7071C6.62334 14.0375 7.12751 14.0884 7.5114 13.8596L7.6129 13.7903L7.70711 13.7071L10 11.415L12.2929 13.7071L12.3871 13.7903C12.7794 14.0953 13.3466 14.0676 13.7071 13.7071C14.0676 13.3466 14.0953 12.7794 13.7903 12.3871L13.7071 12.2929L11.415 10L13.7071 7.70711L13.7903 7.6129C14.0953 7.22061 14.0676 6.65338 13.7071 6.29289C13.3767 5.96245 12.8725 5.91161 12.4886 6.14038L12.3871 6.2097L12.2929 6.29289L10 8.585L7.70711 6.29289L7.6129 6.2097Z"
        fill="#666666"
      />
    </svg>
  );
};

export default Close;
