import { SurveyConfigModule, TextVariant } from "@max/common/setfan";
import { useIsMobile } from "melodies-source/utils/hooks";
import styled, { CSSProperties } from "styled-components";

export const Text = ({ style, content, variants }: SurveyConfigModule) => {
  const isMobile = useIsMobile();

  // let's move away from variants and store them in the css sent over from config
  if (!variants) {
    return (
      <StyledText {...(style as Omit<CSSProperties, "translate">)}>
        {content}
      </StyledText>
    );
  }

  const variant: TextVariant = isMobile ? variants.mobile : variants.desktop;

  switch (variant) {
    case "Body1":
      return <Body1 style={style}>{content}</Body1>;
    case "Body2":
      return <Body2 style={style}>{content}</Body2>;
    case "Headline2":
      return <Headline2 style={style}>{content}</Headline2>;
    case "Headline3":
      return <Headline3 style={style}>{content}</Headline3>;
    case "Headline4":
      return <Headline4 style={style}>{content}</Headline4>;
    case "Label":
      return <Label style={style}>{content}</Label>;
    case "Button":
      return <Button style={style}>{content}</Button>;
    case "Caption":
      return <Caption style={style}>{content}</Caption>;
    case "Subtitle2":
      return <Subtitle2 style={style}>{content}</Subtitle2>;
    default:
      throw new Error(`${variant} is not implemented yet`);
  }
};

const StyledText = styled.div((props: CSSProperties) => ({ ...props }));

const Body1 = styled.div`
  font-size: 15px;
  line-height: 22px;
  font-family: Poppins;
  font-weight: 400;
`;

const Body2 = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-family: Poppins;
  font-weight: 400;
`;

const Headline2 = styled.div`
  font-size: 26px;
  line-height: 36px;
  font-family: Poppins;
  font-weight: 600;
`;

const Headline3 = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-family: Poppins;
  font-weight: 500;
`;

const Headline4 = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: Poppins;
  font-weight: 500;
`;

const Button = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: Poppins;
  font-weight: 600;
`;

export const Caption = styled.div`
  font-size: 10px;
  line-height: 16px;
  font-family: Poppins;
  font-weight: 400;
`;

const Label = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  font-family: Poppins;
`;

const Subtitle2 = styled.div`
  font-size: 11px;
  line-height: 16px;
  font-family: Poppins;
  font-weight: 600;
`;
