import * as React from "react";
import { SVGProps } from "react";
export const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.4375,2 C15.9572992,2 18,4.04270083 18,6.5625 L18,13.4375 C18,15.9572992 15.9572992,18 13.4375,18 L6.5625,18 C4.04270083,18 2,15.9572992 2,13.4375 L2,6.5625 C2,4.04270083 4.04270083,2 6.5625,2 L13.4375,2 Z M13.4375,3.4375 L6.5625,3.4375 C4.8961236,3.4375 3.53437892,4.74178343 3.44244691,6.38516932 L3.4375,6.5625 L3.4375,13.4375 C3.4375,15.1038764 4.74178343,16.4656211 6.38516932,16.5575531 L6.5625,16.5625 L13.4375,16.5625 C15.1038764,16.5625 16.4656211,15.2582166 16.5575531,13.6148307 L16.5625,13.4375 L16.5625,6.5625 C16.5625,4.83661016 15.1633898,3.4375 13.4375,3.4375 Z M10,5.90625 C12.2609157,5.90625 14.09375,7.73908431 14.09375,10 C14.09375,12.2609157 12.2609157,14.09375 10,14.09375 C7.73908431,14.09375 5.90625,12.2609157 5.90625,10 C5.90625,7.73908431 7.73908431,5.90625 10,5.90625 Z M10,7.34375 C8.53299363,7.34375 7.34375,8.53299363 7.34375,10 C7.34375,11.4670064 8.53299363,12.65625 10,12.65625 C11.4670064,12.65625 12.65625,11.4670064 12.65625,10 C12.65625,8.53299363 11.4670064,7.34375 10,7.34375 Z M14.2578125,4.78125 C14.7885236,4.78125 15.21875,5.21147637 15.21875,5.7421875 C15.21875,6.27289863 14.7885236,6.703125 14.2578125,6.703125 C13.7271014,6.703125 13.296875,6.27289863 13.296875,5.7421875 C13.296875,5.21147637 13.7271014,4.78125 14.2578125,4.78125 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
