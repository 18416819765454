import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
} from "Components";
import Dropdown from "Components/Dropdown";

export const DropdownQuestion = ({
  id,
  style,
  header,
  visibility,
  validation,
  ...props
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.[id] as SurveyResponse<string>;

  const handleChange = (selection: string) => {
    setData({
      ...data,
      [id]: {
        ...d,
        value: selection,
      },
    });
  };

  const valid = useValidation(id, validation);

  return (
    <QuestionContainer>
      <HeadlineText {...header} />
      <Dropdown {...props} handleChange={handleChange} value={d?.value} />
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};
