export const MusicNote = ({ className }: { className?: string }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 31 31"
      fill="none"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9163 15.3026L25.833 13.1499V18.6861C25.0684 18.3013 24.1838 18.0834 23.2497 18.0834C20.443 18.0834 18.083 20.0501 18.083 22.6043C18.083 25.1585 20.443 27.1251 23.2497 27.1251C26.0564 27.1251 28.4163 25.1585 28.4163 22.6043V2.35034L10.333 5.36423V19.9778C9.5684 19.5929 8.68378 19.3751 7.74967 19.3751C4.94299 19.3751 2.58301 21.3417 2.58301 23.8959C2.58301 26.4501 4.94299 28.4168 7.74967 28.4168C10.5564 28.4168 12.9163 26.4501 12.9163 23.8959V15.3026ZM10.333 23.8959C10.333 24.9086 9.22319 25.8334 7.74967 25.8334C6.27616 25.8334 5.16634 24.9086 5.16634 23.8959C5.16634 22.8833 6.27616 21.9584 7.74967 21.9584C9.22319 21.9584 10.333 22.8833 10.333 23.8959ZM23.2497 24.5418C24.7232 24.5418 25.833 23.6169 25.833 22.6043C25.833 21.5916 24.7232 20.6668 23.2497 20.6668C21.7762 20.6668 20.6663 21.5916 20.6663 22.6043C20.6663 23.6169 21.7762 24.5418 23.2497 24.5418ZM12.9163 12.6837V7.55264L25.833 5.39986V10.5309L12.9163 12.6837Z"
        fill="#1B0076"
      />
    </svg>
  );
};
