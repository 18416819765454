import { useIsMobile } from "melodies-source/utils/hooks";
import styled from "styled-components";

export const QuestionHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const Headline3 = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-family: Poppins;
  font-weight: 500;
`;

export const Headline4 = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: Poppins;
  font-weight: 500;
`;

export const QuestionContainer = styled.div<{ noBorder?: boolean }>`
  margin-bottom: ${({ noBorder }) => (noBorder ? "0px" : "30px")};

  padding-bottom: 30px;
  ${({ noBorder }) => !noBorder && "border-bottom: 1px solid #d7d7d7"};
`;

export const Validation = styled.div`
  color: #e45c52;
  margin: 10px 0;
`;

export const HeadlineText = (props: any) => {
  const isMobile = useIsMobile();

  return isMobile ? <Headline4 {...props} /> : <Headline3 {...props} />;
};
