const Check = ({
  fillColor,
  className,
}: {
  fillColor: string;
  className?: string;
}) => {
  return (
    <svg
      width="28"
      height="28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 .4C21.511.4 27.6 6.489 27.6 14c0 7.511-6.089 13.6-13.6 13.6C6.489 27.6.4 21.511.4 14 .4 6.489 6.489.4 14 .4Zm8.827 6.67a1.7 1.7 0 0 0-2.246-.095l-.157.144-8.95 9.322-3.875-4.197-.154-.148a1.7 1.7 0 0 0-2.48 2.288l.136.166 6.325 6.852 11.45-11.928.139-.163a1.7 1.7 0 0 0-.188-2.24Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default Check;
