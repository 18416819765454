import { SurveyConfigModule } from "@max/common/setfan";
import { SurveyResponse } from "@max/common/functions/setfan";
import {
  HeadlineText,
  QuestionContainer,
  useDataContext,
  useValidation,
  Validation,
} from "Components";
import { Autocomplete } from "melodies-source/Autocomplete";
import styled from "styled-components";

export const AutocompleteQuestion = ({
  id,
  header,
  options,
  validation,
}: SurveyConfigModule) => {
  const { data, setData } = useDataContext();
  const d = data?.[id] as unknown as SurveyResponse<{
    value: string;
    label: string;
  }>;

  const handleClick = (clickedId: number | string) => {
    setData({
      ...data,
      [id]: {
        ...d,
        value: clickedId,
      },
    });
  };

  const valid = useValidation(id, validation);

  return (
    <QuestionContainer>
      <HeadlineText {...header} />
      <StyledAutocomplete
        onChange={(opt) => handleClick(opt.value)}
        options={options.map((opt) => ({
          label: opt.label,
          value: opt.id.toString(),
        }))}
        onClear={() => handleClick("")}
        value={d?.value}
      />
      {valid && <Validation>{valid}</Validation>}
    </QuestionContainer>
  );
};

const StyledAutocomplete = styled(Autocomplete)`
  margin-top: 10px;
`;
